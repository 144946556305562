<template>
    <section class="gardenManagementPage">
        <!-- <g-breadcrumb bread="会员管理" /> -->
        <div class="dis-flex flex-x-between flex-y-start">
            <div class="dis-flex flex-x-start flex-y-start">
                <el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px">
                    <el-form-item>
                        <el-input v-model.trim="searchForm.dishName" placeholder="请输入菜品名称" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="queryAllPage">搜 索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-button type="success" @click="add" class="turnBule">添加菜品</el-button>
        </div>


        <TableContainer title="">

            <el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
                :cell-style="{ textAlign: 'center' }">

                <el-table-column prop="dishName" label="菜品名称" min-width="60" />
                <el-table-column prop="videoImg" label="菜品图片" min-width="60">
                    <template slot-scope="scope">
                        <div class="p-r">
                            <img :src="scope.row.dishPic" width="60" height="60" />

                        </div>



                    </template>
                </el-table-column>
                <el-table-column prop="price" label="菜品价格" min-width="60" />
                <el-table-column prop="dishUnit" label="菜品规格" min-width="60" />


                <el-table-column width="500" label="菜品操作">
                    <template slot-scope="scope">
                        <!-- <el-button type="text" class="textOrange" @click="detailsClick(scope.row)">详情</el-button> -->
                        <el-button type="text" class="textOrange" @click="editorClick(scope.row)">编辑</el-button>
                        <!-- <el-button type="text" class="textOrange" @click="delteFormClick(scope.row)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
                :size.sync="searchForm.size" @pagination="queryAllPage" />
        </TableContainer>

        <GDialog :dialog.sync="themeDialog" @btnSure="submitThemeForm()" @btnClose="resetThemeForm()" width="50%">
            <el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px" @submit.native.prevent
                class="dis-flex flex-dir-column flex-x-start">
                <el-form-item label="菜品名称" >
                    <el-input v-model="dishName" placeholder="请输入菜品名称" />
                </el-form-item>
                <el-form-item label="菜品图片" >
                    <uploadPics :limit="1" @fileList="imageFileClick" :file-list.sync="imageFileList"
                        @moveList="moveFileClick"></uploadPics>
                </el-form-item>
            </el-form>

        </GDialog>






    </section>
</template>

<script>
import {
    queryAllPage,
    queryDcMeals,
    addBatchDcMeals,
    delDcMeals,
    queryTeamDcMeals,
    addDishes,
    updateDishes

} from '@api/bx/api_contentManagement';
import uploadPics from '@/components/Upload/uploadPics.vue'
export default {
    components: {
        uploadPics,
    },
    data() {
        return {
            leadInForm: {
                voice: [],
                // checkedWords: [1, 2, 3]
            },
            themeDialog: {
                title: '信息框',
                visible: false
            },
            searchForm: {
                page: 1,
                size: 10,
                dishName: ''
                // type: '',
                // dayTime: ''
            },
            total: 0,
            tableData: [],
            typeList: [],
            isEditor: false,
            editorRow: {},
            activeName: '中餐',
            dishName: '',
            morningList: [],
            noonList: [
                {
                    dishName: '',
                    dayTime: '',
                    type: '中餐',
                    price: '',
                    dishPic: '',
                    dishUnit: ''
                },
                {
                    dishName: '',
                    dayTime: '',
                    type: '中餐',
                    price: '',
                    dishPic: '',
                    dishUnit: ''
                },
                {
                    dishName: '',
                    dayTime: '',
                    type: '中餐',
                    price: '',
                    dishPic: '',
                    dishUnit: ''
                },
                {
                    dishName: '',
                    dayTime: '',
                    type: '中餐',
                    price: '',
                    dishPic: '',
                    dishUnit: ''
                },
                {
                    dishName: '',
                    dayTime: '',
                    type: '中餐',
                    price: '',
                    dishPic: '',
                    dishUnit: ''
                },
            ],
            nightList: [],
            readonly: false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < new Date().getTime() - 3600 * 1000 * 24
                },
            },
            imageUrl: '',
            imageFileList: [],
            dishId: ''


        }
    },
    created() {
        this.queryAllPage()
        // this.queryDcMeals()

    },
    methods: {
        tabHeader() {
            return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
        },
        imageFileClick(msg) {
            console.log(msg)
            this.imageUrl = msg.rootPath

        },
        moveFileClick(msg) {
            this.imageFileList = []
            this.imageUrl = ''

        },


        // 添加菜品
        add() {
            this.dishName=''
            this.imageUrl=''
            this.isEditor = false
            this.themeDialog.visible = true
        },


        // 初始化列表
        queryAllPage() {
            queryAllPage(this.searchForm).then(res => {
                this.tableData = res.records
                this.total = res.total
            })
        },


        //编辑 
        editorClick(row) {
            this.isEditor = true
            this.dishName = row.dishName
            this.dishId = row.id
            this.imageFileList = [
                {
                    name: '',
                    url: row.dishPic
                }
            ]
            this.imageUrl = row.dishPic

            this.themeDialog.visible = true

        },
        //确定按钮
        submitThemeForm() {
            if( this.dishName==''){
                this.$message.warn('请填写菜名')
                return
            }
            if( this.imageUrl==''){
                this.$message.warn('请上传菜品图片')
                return
            }
            if (this.isEditor) {
                updateDishes({
                    dishName: this.dishName,
                    dishPic: this.imageUrl,
                    id: this.dishId
                }).then(res => {
                    this.themeDialog.visible = false
                    this.$message.success('更新成功')
                    this.queryAllPage()
                })
            } else {
                addDishes({
                    dishName: this.dishName,
                    dishPic: this.imageUrl,
                }).then(res => {
                    this.themeDialog.visible = false
                    this.$message.success('新增成功')
                    this.queryAllPage()
                })
            }

        },
        //取消按钮
        resetThemeForm() {
            this.themeDialog.visible = false
        }


    }
}
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
    color: #fff;
    background-color: #58e3e8;
    border-color: rgba(56, 185, 190, 0.12);
    margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
    background: rgba(56, 185, 190, 0.5);
    border-color: rgba(56, 185, 190, 0.5);
    color: #fff;
}

.el-button--text.reviewed {
    color: #38b9be;
}

.reviewed {
    color: #38b9be;
}

.el-tree-node__content {
    z-index: 999;
    padding-left: 40px;

}

.directory-in {
    width: 32%;
    line-height: 60px;
    background: #4095E5;
}
</style>

